/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import envelop from "../assets/icon/envelop.png";
import phone from "../assets/icon/phone.png";
import whatsapp from "../assets/icon/whatsapp.png";
import { AdultIcon, KidsIcon } from "./Icon";
import { useGlobalContext } from "../utils/ContextProvider.jsx";
import { useParams } from "react-router-dom";
import { useEffect,useState } from "react";

const ServiceInformation = () => {
	const { tableID } = useParams();
	const { data2,completeData,fetchedID } = useGlobalContext();
	const table=tableID==='FB'?'E':tableID==='PR'?'H':null
	const [rooms, setRooms] = useState([]);

function createRoomObjects(input) {
  const roomRegex = /stanza (\d+) \|\| (\d+) adulti - (\d+) bambini(?: \(([\d-]+)\))?/g;
  const rooms = [];
  let match;

  while ((match = roomRegex.exec(input)) !== null) {
    const roomId = parseInt(match[1], 10);
    const adults = parseInt(match[2], 10);
    const children = parseInt(match[3], 10);
    const ages = match[4] ? match[4].split('-').map(age => parseInt(age, 10)) : [];

    const room = {
      id: roomId,
      adults: adults,
      child: {
        value: children,
        ages: ages,
      },
    };

    rooms.push(room);
  }

  return rooms;
}


	useEffect(()=>{
	if(data2&&data2["Tipi di Camera"])
	setRooms(()=>createRoomObjects(data2["Tipi di Camera"]))

	if(data2===undefined&&completeData)
	alert('Il preventivo non è stato caricato correttamente, il testo sotto non è reale ma solo un esempio di come sarà la pagina. Contatta il supporto di InfoIschia')
	}
	,[data2])


	return (
		<section className="service-info-section">
			<div className="container">
				<div className="service-info-card">
					<div className="row align-items-center">
						{
						(tableID==='PR'&&data2&&rooms[0]?.adults)
						||
						(tableID==='FB'&&data2&&rooms[0]?.adults)
						?(
						<div className="col-lg-8">
							
								{data2["Tipi di Camera"] && 
									rooms.map((room, index) => (
										<div className="item" key={index}>
											<h5 className="title"> Numero di Persone ( Stanza {room.id}) </h5>
											<div className="d-flex flex-wrap gap-4">
												<div className="form-group"
													style={{
														maxWidth: "240px",
														width: "90px",
														flexGrow: "1",
													}}
												>
													<label>
														<span className="form-label">Adulti(+18)</span>
														<div className="position-relative">
															<span className="left-icon">
																<AdultIcon />
															</span>
															<input
																type="number"
																className="form-control"
																placeholder={room.adults}
																disabled
																style={{ backgroundColor: "#ffffff" }}
															/>
														</div>
													</label>
												</div>

												<div
													className="form-group"
													style={{ maxWidth: "140px" }}
												>
													<label>
														<span className="form-label">BAMBINI:</span>
														<div className="position-relative">
															<span className="left-icon">
																<KidsIcon />
															</span>
															<input
																type="number"
																className="form-control"
																placeholder={room.child.value}
																disabled
																style={{ backgroundColor: "#ffffff" }}
															/>
														</div>
													</label>
												</div>
														<div className="d-flex gap-4">
													
															{room?.child?.ages?.map((age, index) => (
																<div className="form-group" style={{ maxWidth: "50px" }}>
													<label>
												<span className="form-label">età {index + 1}</span>
												<div className="position-relative">
													<input
														type="number"
														className="form-control text-center"
														placeholder={age}
														disabled
														style={{ backgroundColor: "#ffffff" }}
													/>
												</div>
											</label>
															</div>

															))}
														</div>
											</div>
										</div>
									))
								}
							
						</div>
						):
						(tableID==='FB'&&data2) 
							||
						(tableID==='PR')
						?(<>
						<div className="col-lg-8">
							<div className="item">
								<h5 className="title">Sistemazione</h5>
								<div className="d-flex flex-wrap gap-4">
									<div
										className="form-group"
										style={{
											maxWidth: "240px",
											width: "90px",
											flexGrow: "1",
										}}
									>
										<label>
											<span className="form-label">
												{data2["Tipi di Camera"]}
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>	
						</>):(<>
						<div className="col-lg-8">
							<div className="item">
								<h5 className="title">Numero di Persone ( Stanza 1)</h5>
								<div className="d-flex flex-wrap gap-4">
									<div
										className="form-group"
										style={{
											maxWidth: "240px",
											width: "90px",
											flexGrow: "1",
										}}
									>
										<label>
											<span className="form-label">Adulti(+18)</span>
											<div className="position-relative">
												<span className="left-icon">
													<AdultIcon />
												</span>
												<input
													type="number"
													className="form-control"
													placeholder="0"
													disabled
													style={{ backgroundColor: "#ffffff" }}
												/>
											</div>
										</label>
									</div>

									<div
										className="form-group"
										style={{ maxWidth: "140px" }}
									>
										<label>
											<span className="form-label">BAMBINI:</span>
											<div className="position-relative">
												<span className="left-icon">
													<KidsIcon />
												</span>
												<input
													type="number"
													className="form-control"
													placeholder="0"
													disabled
													style={{ backgroundColor: "#ffffff" }}
												/>
											</div>
										</label>
									</div>
								</div>
							</div>

							<div className="item">
								<h5 className="title">Numero di Persone ( Stanza 2)</h5>
								<div className="d-flex flex-wrap gap-4">
									<div
										className="form-group"
										style={{
											maxWidth: "240px",
											width: "90px",
											flexGrow: "1",
										}}
									>
										<label>
											<span className="form-label">Adulti(+18)</span>
											<div className="position-relative">
												<span className="left-icon">
													<AdultIcon />
												</span>
												<input
													type="number"
													className="form-control"
													placeholder="0"
													disabled
													style={{ backgroundColor: "#ffffff" }}
												/>
											</div>
										</label>
									</div>
									<div
										className="form-group"
										style={{ maxWidth: "140px" }}
									>
										<label>
											<span className="form-label">BAMBINI:</span>
											<div className="position-relative">
												<span className="left-icon">
													<KidsIcon />
												</span>
												<input
													type="number"
													className="form-control"
													placeholder="0"
													disabled
													style={{ backgroundColor: "#ffffff" }}
												/>
											</div>
										</label>
									</div>
									<div className="d-flex gap-4">
										<div
											className="form-group"
											style={{ maxWidth: "50px" }}
										>
											<label>
												<span className="form-label">età 1:</span>
												<div className="position-relative">
													<input
														type="number"
														className="form-control text-center"
														placeholder="0"
														disabled
													style={{ backgroundColor: "#ffffff" }}
													/>
												</div>
											</label>
										</div>
										<div
											className="form-group"
											style={{ maxWidth: "50px" }}
										>
											<label>
												<span className="form-label">età 2:</span>
												<div className="position-relative">
													<input
														type="number"
														className="form-control text-center"
														placeholder="0"
														disabled
													style={{ backgroundColor: "#ffffff" }}
													/>
												</div>
											</label>
										</div>
										<div
											className="form-group"
											style={{ maxWidth: "50px" }}
										>
											<label>
												<span className="form-label">età 3:</span>
												<div className="position-relative">
													<input
														type="number"
														className="form-control text-center"
														placeholder="0"
														disabled
													style={{ backgroundColor: "#ffffff" }}
													/>
												</div>
											</label>
										</div>
										<div
											className="form-group"
											style={{ maxWidth: "50px" }}
										>
											<label>
												<span className="form-label">età 4:</span>
												<div className="position-relative">
													<input
														type="number"
														className="form-control text-center"
														placeholder="0"
														disabled
													style={{ backgroundColor: "#ffffff" }}
													/>
												</div>
											</label>
										</div>
									</div>
								</div>
							</div>

						</div>
						</>)
						}



						<div className="col-lg-4">
							<div className="side-content">
								<h3 className="name">
									{/* print string upto || */}
									{data2?data2["Periodo Soggiorno"]?.split("||")[0]:"4 Notti - 5 Giorni"}
									</h3>
								<span className="subname">
									{/* print string after || */}
									{data2?data2["Periodo Soggiorno"]?.split("||")[1]:"27 Aprile 2023 - 30 Aprile 2023"}
									
								</span>
		
								<p >
									Con viaggio da: {data2?data2["Città di Partenza"]+" ":"Firenze"+" " }
									incluso di passaggi marittimi e trasferimenti fino all'hotel A/R
								</p>
								<p className="price-total mb-2">Totale Preventivo</p>
								<h3 className="price">
									{data2&&data2["Totale Soggiorno"]?data2["Totale Soggiorno"]:"0"}
									€</h3>
								<p>Contattaci per bloccare il preventivo</p>
								<SocialIcons fetchedID={fetchedID} tableID={tableID} completeData={completeData}/>
								<div className="text-center codice">
									Codice Preventivo 
									#{data2?`${fetchedID}-${tableID}-${completeData.id}`:"4245"}
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const SocialIcons = ({ onlyIcons,fetchedID,tableID,completeData }) => {
	const WhatsAppURL=`https://api.whatsapp.com/send/?phone=3908119758555&text=Preventivo${" "}ID${" "}${fetchedID}-${tableID}-${completeData?.id}&type=phone_number&app_absent=0`
	return (
		<div className={`social ${onlyIcons ? "icons-only" : ""}`}>
			<a href="Tel:+390810118098">
				<img src={phone} alt="" /> <span>Chiama</span>
			</a>
			<a
				href={WhatsAppURL}
				target="_blank"
				rel="noreferrer"
			>
				<img src={whatsapp} alt="" /> <span>Scrivici</span>
			</a>
			<a href="Mailto:booking@infoischia.com">
				<img src={envelop} alt="" /> <span>Scrivici</span>
			</a>
		</div>
	);
};
export default ServiceInformation;
