/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FacebookIcon, InstagramIcon } from "./Icon";

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="footer-top">
					<div className="footer-widget">
						<p className="m-0">
							Per ogni esigenza contattaci al Servizio Booking:
						</p>
						<a href="Tel:+39 081 011 8098" className="footer-widget-link">
							+39 081 011 8098
						</a>
					</div>
					<div className="footer-widget">
						<div className="title">Infoischia</div>
						<p className="mt-3 mb-1">
							I nostri uffici sono apertitutti i giorni incluso sabato e
							domenica
						</p>
						<p>
							dalle <span className="text-base-2">08:00</span> alle{" "}
							<span className="text-base-2">21:00</span> <br />
							(giugno/settembre dalle{" "}
							<span className="text-base-2">07:00</span> alle{" "}
							<span className="text-base-2">23:00</span>)
						</p>
					</div>
					<div className="footer-widget">
						<div className="title">Seguici in tempo reale su:</div>
						<div className="d-flex flex-wrap gap-4 mt-3">
							<a
								href="https://www.facebook.com/infoischia/"
								target="_blank"
								rel="noreferrer"
							>
								<FacebookIcon />
							</a>
							<a
								href="https://www.instagram.com/infoischia/"
								target="_blank"
								rel="noreferrer"
							>
								<InstagramIcon />
							</a>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<p className="copyright-txt">
						&copy; Copyright 2023 Tutti i diritti riservati
					</p>

					<p>P. IVA 09048651211</p>
					{/* <p className="m-0">
						Pursuant to Legislative Decree 196/2003, it is specified that
						the information contained in this message is confidential and
						for the exclusive use of the recipient. If this message was
						received by mistake, we invite you to delete it without
						copying it and not to forward it to third parties, kindly
						notifying us. Thank you.
					</p> */}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
