/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import{useGlobalContext} from "../utils/ContextProvider.jsx";
import { useEffect,useState } from "react";
import { useRef } from "react";

const Maps = () => {
	const fixedmap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.087442429628!2d-75.41065792355752!3d39.984151181853335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6eba3a5e21793%3A0xebb0deb5d4cdc256!2sElgin%20Rd%2C%20Newtown%20Square%2C%20PA%2019073%2C%20USA!5e0!3m2!1sen!2sbd!4v1683639400291!5m2!1sen!2sbd"
	const {data}=useGlobalContext()
	const MAP_API_KEY = 'AIzaSyCIki5x51tsweEPEQAYpfafMSjt5J56SUQ'
	const [mapUrl, setMapUrl] = useState('')
	const mapRef = useRef(null)
	let zoomValue=20
	// latitudine
	// longitudine

	useEffect(() => {
		if(data.length>0){
			let latitudine=data[0]["fields"]["latitudine"]
			let longitudine=data[0]["fields"]["longitudine"]
			let mapUrl = `https://www.google.com/maps/embed/v1/place?key=${MAP_API_KEY}&q=${latitudine},${longitudine}&zoom=13`
			setMapUrl(() => mapUrl)
		}
	}, [data])

	return (
		<>
			<div className="position-relative">
				<iframe
				    ref={mapRef}
					src={mapUrl?mapUrl:fixedmap}
					allowFullScreen
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					className="map"
				></iframe>
				<div className="map-content">
					<h5>Località:</h5>
					<p>
						{data.length>0?data[0]["fields"]["località"]:
						"6391 Elgin St. Celina, Delaware 10299"}
						</p>
					{/* <a className="btn-base" 
					onClick={
						()=>{
							setMapUrl(()=>mapUrl+`&zoom=${zoomValue}`)
						}
					}
					>
						Centra sull'hotel
					</a> */}
				</div>
			</div>
		</>
	);
};

export default Maps;
