import React from "react";
import { AdultIcon, KidsIcon } from "./Icon";

const Rooms = ({room,index}) => {
	return (
		<>
		{room?(<>
			<div className="item">
				<h5 className="title">Numero di Persone ( Stanza {index+1})</h5>
				<div className="d-flex flex-wrap gap-10px">
					<div className="form-group" style={{ maxWidth: "65px" }}>
						<InputItem title="Adulti(+18)" icon={<AdultIcon />} placeholdervalue={room.adults} />
					</div>
					<div className="form-group" style={{ maxWidth: "65px" }}>
						<InputItem title="Bambini" icon={<KidsIcon />} placeholdervalue={room.child.value} />
					</div>
					{room.child.ages.map((age,index)=>(
						<div className="form-group" style={{ maxWidth: "34px" }}>
						<InputItem title={`età ${index+1}:`} placeholdervalue={age} />
					</div>
					))}

				</div>
			</div>
		</>):(
<div className="item">
				<h5 className="title">Numero di Persone ( Stanza 1)</h5>
				<div className="d-flex flex-wrap gap-10px">
					<div className="form-group" style={{ maxWidth: "65px" }}>
						<InputItem title="Adulti(+18)" icon={<AdultIcon />} />
					</div>
					<div className="form-group" style={{ maxWidth: "65px" }}>
						<InputItem title="Adulti(+18)" icon={<KidsIcon />} />
					</div>
					<div className="form-group" style={{ maxWidth: "34px" }}>
						<InputItem title="età 1:" />
					</div>
					<div className="form-group" style={{ maxWidth: "34px" }}>
						<InputItem title="età 2:" />
					</div>
				</div>
			</div>
		)}
			
		</>
	);
};
const InputItem = ({ title, icon,placeholdervalue }) => {
	return (
		<label>
			{title && <span className="form-label">{title}</span>}
			<div className="position-relative">
				{icon && <span className="left-icon">{icon}</span>}
				<input
					type="number"
					className="form-control w-100"
					placeholder={placeholdervalue?placeholdervalue:"0"}
					style={{
						paddingLeft: icon ? "35px" : "",
						backgroundColor: "#ffffff",
					}}
					disabled

				/>
			</div>
		</label>
	);
};

export default Rooms;
