import React from "react";
import BannerSlider from "./BannerSlider";
import { Star } from "./Icon";
import {useEffect} from 'react'
import {useGlobalContext} from '../utils/ContextProvider.jsx'

const Banner = () => {
	const {fetchFirstTableData,data,data2,fetchedID}=useGlobalContext()
	// useEffect(()=>{
	// 	fetchFirstTableData()
	// }	,[fetchedID])



	return (
		<>
			<section className="banner-section">
				<div className="banner-top">
					<div className="container fadeInUp">
						<div style={{ maxWidth: "601px" }}>
							<h2 className="title">Gentile 
								{data2?" "+data2?.["Nome"]+ " "+data2?.["Cognome"]:(`
							Roberta Riviera`)}
							</h2>
							<p className="subtitle">
								In merito alla tua richiesta di seguito troverai nostra
								migliore proposta: Pacchetto Ischia:{" "}
								<strong>
									{data.length>0?data[0]?.fields["nomePacchetto"]+ " ":"Hotel Pinco pallo"}
								</strong>
								 con viaggio da: <strong>
									{data2?data2?.["Città di Partenza"]+ " ":"viaggio incluso"}
									</strong>
							</p>
						</div>
					</div>
				</div>
				<div className="banner-bottom">
					<div className="container">
						<div className="row gx-4 gy-5">
							<div className="col-lg-8 fadeInLeft mobile-change">
								<BannerSlider />
							</div>
							<div className="col-lg-4 fadeInRight">
								<div className="reviews-card">
									<h5 className="title">
										{data.length>0?data[0]?.fields["nomeHotel"]+ " ":"Il tuo hotel"}
										</h5>
									<div className="subtitle d-flex justify-content-between">
										<span>
											Il tuo Hotel
										</span>
										<div className="d-flex gap-2">
											{data?.length>0?(
												Array.from({length: data[0]?.fields["numeroStelle"]}, (_, i) => (
													<Star key={i} />
												))

											):(
												<>
												<Star />
												<Star />
												</>
											)}
										</div>
									</div>
									<p className="text m-0" style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "3",
										WebkitBoxOrient: "vertical",
										height: "330px",
									}}>
										{data.length>0?data[0]?.fields["descrizioneHotel"]:(
										`Lorem ipsum dolor sit amet consectetur
										adipiscininterdum rutrum aenean velit, quis
										aliquam rem ipsum dolor sit amet consectetur
										adipiscininterdum rutrum aenean velit, quis
										aliquam convallis posuere primisconvallis posuere
										primis lor sit amet consectetur adipiscininterdum
										rutrum aenean velit, quis aliquam convallis
										posuere primisconvallis posup velit, quis aliquam
										convallis posuere primisconvallis posup jjgg
										Jknfknvngk gk ngk nkgn kdnkg nkdgk nkdnkdngk
										kgnkdlaj kgl kagk mg{" "} 
										`)}
									</p>							
										<a className="text-base" href="#more-txt">
											continua...
										</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Banner;
