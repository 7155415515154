import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {ContextProvider} from './utils/ContextProvider'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ContextProvider>
		<App />
		</ContextProvider>
	</React.StrictMode>
);
