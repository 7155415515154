import React, { useState,useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import Rooms from "./Rooms";
import { SocialIcons } from "./ServiceInformation";
import { useGlobalContext } from "../utils/ContextProvider.jsx";
import { useParams } from "react-router-dom";
import { AdultIcon, KidsIcon } from "./Icon";

const MobilePopup = () => {
	const [open, setOpen] = useState(false);
	const { data2,completeData ,fetchedID} = useGlobalContext();
	const { tableID } = useParams();
	const [rooms, setRooms] = useState([]);

	const handlers = useSwipeable({
		onSwipedUp: () => setOpen(true),
		onSwipedDown: () => setOpen(false),
	});

	function createRoomObjects(input) {
	const roomRegex = /stanza (\d+) \|\| (\d+) adulti - (\d+) bambini(?: \(([\d-]+)\))?/g;
	const rooms = [];
	let match;

	while ((match = roomRegex.exec(input)) !== null) {
		const roomId = parseInt(match[1], 10);
		const adults = parseInt(match[2], 10);
		const children = parseInt(match[3], 10);
		const ages = match[4] ? match[4].split('-').map(age => parseInt(age, 10)) : [];

		const room = {
		id: roomId,
		adults: adults,
		child: {
			value: children,
			ages: ages,
		},
		};

		rooms.push(room);
	}

	return rooms;
	}
	
	
	useEffect(()=>{
	if(data2&&data2["Tipi di Camera"])
	setRooms(()=>createRoomObjects(data2["Tipi di Camera"]))
	
	}
	,[data2])

	return (
		<>
			{open && (
				<div
					className={`__offcanvas ${open ? "active" : ""}`}
					onClick={() => setOpen(false)}
				></div>
			)}
			<div
				className={`mobile-popup d-sm-none ${open ? "active" : ""}`}
				{...handlers}
			>
				<div className="mobile-popup-content">
					<div
						className="mobile-popup-title text-center"
						onClick={() => setOpen(!open)}
					>
						<span>Clicca qui per vedere maggiori dettagli</span>
					</div>
					<div
						className={`mobile-popup-middle`}
						onClick={() => setOpen(true)}>
						{
						(tableID==='PR'&&data2&&rooms[0]?.adults)
								||
						(tableID==='FB'&&data2&&rooms[0]?.adults)?(
							<>
							{rooms.map((room, index) => (
								<Rooms index={index} room={room} key={index} />
							))}
							</>
							):
						(tableID==='FB'&&data2) 
							||
						(tableID==='PR')?(<>
							<div className="mobile-popup-middle__title">
								<span className="font-bold">Sistemazione <br/></span>
									<label>
										<span className="form-label">
											{data2["Tipi di Camera"]}
										</span>
									</label>

							</div>
						</>)
							:
							(<Rooms />)
						}
						
						<hr />
					</div>
				</div>
			</div>
			<div
				className="mobile-popup-bottom d-sm-none"
				onClick={() => setOpen(true)}
			>
				<div>
					<p className="m-0">Totale Preventivo</p>
					<h3 className="price mb-3">{data2&&data2["Totale Soggiorno"]?data2["Totale Soggiorno"]:"0"}€</h3>
				</div>
				<div className="subtitle text-center">
					<div className="subtitle">
						Contattaci per bloccare il preventivo
					</div>
					<SocialIcons onlyIcons fetchedID={fetchedID} tableID={tableID} completeData={completeData}/>
					<p
						style={{
							fontWeight: "500",
							color: "#585C67",
							margin: "0",
						}}
					>
						Codice Preventivo #
						 {data2?`${fetchedID}-${tableID}-${completeData.id}`:"4245"}
					</p>
				</div>
			</div>
		</>
	);
};

export default MobilePopup;
