import React, { useState,useEffect } from "react";
import img from "../assets/img/slide/slide-1.png";
import img2 from "../assets/img/slide/slide-2.png";
import img3 from "../assets/img/slide/slide-3.png";
import img4 from "../assets/img/slide/slide-4.png";
import { useGlobalContext } from "../utils/ContextProvider";

const data2 = [
	img,
	img2,
	img3,
	img4,
	img,
	img2,
	img3,
	img4,
	img,
	img2,
	img3,
	img4,
	img,
	img2,
	img3,
	img4,
];

const BannerSlider = () => {
	const { data,fetchedID} = useGlobalContext();
	const [active, setActive] = useState(0);
	const [images, setImages] = useState(data2);
	const [immaginiHotel, setImmaginiHotel] = useState([])
	useEffect(()=>{
		// extract immaginiHotel from data fields
		if(data.length>0 && data[0].fields["immaginiHotel"]!==undefined &&fetchedID!==""){
			let images=[]
			data.map((item)=>{
				if(item.fields["immaginiHotel"]!==undefined)
				images.push(item.fields["immaginiHotel"])
				
			}

			)
			setImmaginiHotel(images)
		}
	},[data])

	useEffect(()=>{	
		immaginiHotel.length>0?setImages(immaginiHotel):setImages(data2)
		
	}
	,[immaginiHotel])



	return (
		<>
			<div className="slide-wrapper">
				<div
					className={`main-img ${
						active % 2 === 0 ? "fadeInRight" : "fadeInLeft"
					}`}
				>
					<img src={images[active]} alt="" />
				</div>
				<div className="slide-images">
					<div className="inner">
						{images?.map((item, i) => (
							<div
								className={`img ${active === i ? "active" : ""}`}
								onMouseOver={() => setActive(i)}
								key={i}
							>
								<img src={item} alt="" />
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default BannerSlider;
