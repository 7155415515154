import React, { useEffect } from 'react';
import Banner from '../components/Banner';
import Faqs from '../components/Faqs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Maps from '../components/Maps';
import MobilePopup from '../components/MobilePopup';
import ServiceInformation from '../components/ServiceInformation';
import { useGlobalContext } from '../utils/ContextProvider.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Triangle } from 'react-loader-spinner';
import ErrorPage from '../components/404';

const Loader = () => {
    return (
        <Triangle
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="triangle-loading"
            wrapperStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
            wrapperClassName=""
            visible={true}
        />
    );
};
const Home = () => {
    const { modulo, tableID, recordID } = useParams();
    const { setCompleteData, setData2, setUNIQUEID, UNIQUEID, loading } = useGlobalContext();
    const database2Id = 'tvwj36nkrvym';
    const teamId = 'nHhB2Tff7X8xu5FTk';
    const apiKey = 'a7e467a0-e46f-11ed-862c-b7f12f061ab6';
    const table2Id = tableID === 'FB' ? 'E' : tableID === 'PR' ? 'H' : null;
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    const [error, setError] = useState(false);

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if (modulo) setUNIQUEID(() => modulo);
        // setTimeout(() => {
        //     setLoader(() => false);
        // }, 3000);
    }, [modulo]);
    useEffect(() => {
        try {
            fetch(
                `https://api.ninoxdb.de/v1/teams/${teamId}/databases/${database2Id}/tables/${table2Id}/records/${recordID}`,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((response) => {
                    if (response.status != 200) window.location.href = '/error';
                    return response.json();
                })
                .then((data) => {
                    setData2(() => data.fields);
                    setCompleteData(() => data);
                })
                .catch((err) => {
                    window.location.href = '/error';
                });
        } catch {
            window.location.href = '/error';
        }
    }, []);

    return (
        <>
            {loading ? (
                <Loader />
            ) : error ? (
                <ErrorPage />
            ) : (
                <>
                    <Header />
                    <Banner />
                    <ServiceInformation />
                    <Faqs />
                    <Maps />
                    <Footer />
                    <MobilePopup />
                </>
            )}
        </>
    );
};

export default Home;
