import React, { createContext, useState, useContext } from 'react';
import { useEffect } from 'react';
const Context = createContext();
export const ContextProvider = ({ children }) => {
    const [state, setState] = useState(true);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [completeData, setCompleteData] = useState([]);
    const [params, setParams] = useState([]);
    const [UNIQUEID, setUNIQUEID] = useState('');
    const [tableID, setTableID] = useState('');
    const [fetchedID, setFetchedID] = useState('');
    const [loading, setLoading] = useState(true);
    // /preventivi/:modulo/:tableID/:recordID

    /*___Ninox Keys___ */
    const apiKey = 'a7e467a0-e46f-11ed-862c-b7f12f061ab6';
    const teamId = 'nHhB2Tff7X8xu5FTk';
    const databaseId = 'rt3vs6h5iy47';

    /*_________________ */

    const fetchTablefromFirstDB = () => {
        let tablesID = [];
        //fetch tableID

        if (UNIQUEID === '' || UNIQUEID === undefined) return;
        fetch(`https://api.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}/tables`, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        })
            .then((response) => response.json())
            .then(async (data) => {
                if (data.length > 0) {
                    tablesID = data.map((item) => item.id);
                } else {
                    // Handle the case where UNIQUEID is not found
                    console.log('UNIQUEID not found in the response.');
                }
                // check if tableID is present in the array of tablesID
                for (let i = 0; i < tablesID.length; i++) {
                    const id = await checkTableID(tablesID[i], i === tablesID.length - 1);

                    if (id) break;
                }

                // if (!tableFound) window.location.href = '/error';
            })
            .catch((error) => {
                window.location.href = '/error';
            });
    };
    useEffect(() => {
        if (UNIQUEID) {
            setLoading(true);
            fetchTablefromFirstDB();
        }
    }, [UNIQUEID]);

    useEffect(() => {
        if (fetchedID) {
            fetchFirstTableData(fetchedID);
        }
    }, [tableID]);

    // create a function that will take tableID as arguments and return true if field UNIQUEID matches
    const checkTableID = async (tableIDinner, final = false) => {
        console.log(final, tableID);
        try {
            const response = await fetch(
                `https://api.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}/tables/${tableIDinner}/records`,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            );

            const data = await response.json();

            if (data.length === 0) window.location.href = '/error';

            if (data[0].fields.UNIQUEID === UNIQUEID) {
                console.log(data);
                setTableID(() => tableIDinner);
                setFetchedID(() => tableIDinner);
                return tableIDinner;
            } else if (final) {
                window.location.href = '/error';
            }
            return false;
        } catch (err) {
            window.location.href = '/error';
        }
    };

    const fetchFirstTableData = (tid) => {
        console.log('Fetching', tid, fetchedID);
        if (fetchedID === '' && tid === '') window.location.href = '/error';

        fetch(`https://api.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}/tables/${tid}/records?perPage=10000`, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setData(() =>
                    // extract only the fields from all the data
                    data.map((item) => {
                        return {
                            id: item.id,
                            fields: item.fields,
                        };
                    })
                );
                setLoading(false);
            })
            .catch((error) => (window.location.href = '/error'));
    };
    // useEffect(()=>{
    // 	fetchFirstTableData()
    // }	,[fetchedID])

    return (
        <Context.Provider
            value={{
                state,
                setState,
                data,
                setData,
                fetchFirstTableData,
                data2,
                setData2,
                completeData,
                fetchedID,
                setFetchedID,
                params,
                setParams,
                setCompleteData,
                UNIQUEID,
                setUNIQUEID,
                fetchTablefromFirstDB,
                loading,
            }}
        >
            {children}
        </Context.Provider>
    );
};
export const useGlobalContext = () => useContext(Context);
