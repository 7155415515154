import './App.scss';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorPage from './components/404';

// preventivi/modulocolella202312/PR/489
function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/preventivi/:modulo/:tableID/:recordID" element={<Home />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
