import camere from "../assets/icon/camere.png";
import hotel from "../assets/icon/hotel.png";
import ristorante from "../assets/icon/ristorante.png";
import servizi from "../assets/icon/servizi.png";
import spa from "../assets/icon/spa.png";
export const faqData1 = [
	{
		title: "What is the best way to enjoy this trip?",
		text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
	},
	{
		title: "How can I get a full refund of this trip if I don’t go?",
		text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
	},
	{
		title: "How can I get a full refund of this trip if I don’t go?",
		text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
	},
	{
		title: "What is the cancellation policy?",
		text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
	},
	{
		title: "When the musics over turn off the light?",
		text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
	},
];
export const faqData2 = [
  {
    title: "Hotel",
    text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
    icon: hotel,
  },
  {
    title: "Camere",
    text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
    icon: camere,
  },
  {
    title: "Ristorante",
    text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
    icon: ristorante,
  },
  {
    title: "Spa & Terme",
    text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
    icon: spa,
  },
  {
    title: "Servizi Inclusi",
    text: "Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicrum. Sed ut perspiciatis unde asi architecto beata omnis iste natus error sit voluptatem accusantium dolore mque laudantium.",
    icon: servizi,
  },
];
