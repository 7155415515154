import React from "react";
import logo from "../assets/img/logo.png";
import { Phone } from "./Icon";
const Header = () => {
	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<a href="#" className="logo">
						<img src={logo} alt="" />
					</a>
					<a href="Tel:+390810118098" className="phone-contact">
						<div className="icon">
							<Phone />
						</div>
						<div className="info d-none d-sm-block">
							<span>Prenotazioni</span>
							<div className="number">+39 081 011 8098</div>
						</div>
					</a>
				</div>
			</div>
		</header>
	);
};

export default Header;
