import React from 'react';

import error from '../assets/img/404.png';

import envelop from '../assets/icon/envelop.png';

import whatsapp from '../assets/icon/whatsapp.png';

import phone from '../assets/icon/phone.png';
import logo from '../assets/img/logo.png';

const buttons = [
    {
        icon: phone,
        text: 'Chiama',
        color: '#26529C',
        href: 'Tel:+390810118098',
    },
    {
        icon: whatsapp,
        text: 'Scrivici',
        color: '#60D76A',
        href: 'https://api.whatsapp.com/send/?phone=3908119758555&type=phone_number&app_absent=0',
    },
    {
        icon: envelop,
        text: 'Scrivici',
        color: '#585C67',
        href: 'Mailto:booking@infoischia.com',
    },
];

function ErrorPage() {
    return (
        <div className="errorContainer">
            <div className="errorWrapper">
                <div className="logoContainer">
                    <img src={logo} alt="" />
                </div>
                <div className="error_text_container">
                    <h2 className="errorFour">4</h2>
                    <img src={error} alt="" />
                    <h2 className="errorFour">4</h2>
                </div>
                <div className="error_text_section">
                    <h3 className="sectionTitle">Gentile Cliente</h3>
                    <p className="errorDescription">
                        Ci scusiamo per l'inconveniente, ma attualmente il nostro sito è in manutenzione e non è attivo.
                        A causa di questo imprevisto, ti consigliamo di contattarci telefonicamente, tramite WhatsApp o
                        inviarci una e-mail per richiedere un preventivo dell'offerta desiderata. Grazie per la
                        comprensione.
                    </p>
                </div>
                <div className="error_buttons_container">
                    {buttons.map((button) => (
                        <a href={button.href}>
                            <button className="contactButton" style={{ backgroundColor: button.color }}>
                                <img className="button_image_error" src={button.icon} alt={`button ${button.text}`} />
                                <span className="button_text_error">{button.text}</span>
                            </button>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
