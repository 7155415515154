/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import trustpilot from "../assets/img/trustpilot.png";
import FaqItems from "./FaqItems";
import { Facebook, WhatsApp } from "./Icon";
import { faqData1, faqData2 } from "./data";
import { useGlobalContext } from "../utils/ContextProvider.jsx";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
const Faqs = () => {
	const { tableID } = useParams();
	const { data,completeData,fetchedID } = useGlobalContext();

	const [faqData1Fetch, setFaqData1Fetch] = useState([]);
	const [faqData2Fetch, setFaqData2Fetch] = useState([faqData2]);
	useEffect(()=>{
		if(data.length>0)
		{
			console.log(data[3].fields["dettagliStruttura"])
			setFaqData1Fetch(
				()=>{
						let counter=0;
						data.forEach((item)=>{
							if(item["fields"]["faqTitolo"]!==null && item["fields"]["faqTitolo"]!==undefined)
							counter++;
						})
					return data.slice(0,counter).map((item)=>{
						return {
							title:item["fields"]["faqTitolo"],
							text:item["fields"]["faqDescrizione"]
						}
					}	)
				}
				)
			
			setFaqData2Fetch(
				()=>{
					return data.slice(0,5).map((item,index)=>{
						return {
							// only replace value of text field
							...faqData2[index],
							text:item["fields"]["dettagliStruttura"]
							}
					}	)
				})
			
			
		}
		
	},[data])


	return (

		<section className="faqs-section">
			<div className="container">
				<FaqTitle />
				<div className="invia-per-msg">
					<span className="d-none d-sm-block">Invia per messaggio:</span>
					<span className="d-sm-none"> Invia per messaggio:</span>
					{/* <a href="#">
						<Facebook />
					</a> */}
					<a onClick={()=>{
						window.open(`https://api.whatsapp.com/send/?phone=3908119758555&text=Preventivo${" "}ID${" "}${fetchedID}-${tableID}-${completeData?.id}&type=phone_number&app_absent=0`)
					}}
					>
						<WhatsApp />
					</a>
				</div>
				<div className="faq-wrap">
					<h4 className="frequent-title">
						<span className="d-none d-sm-block">Domande Frequenti</span>
						<span className="d-sm-none">Domande Frequenti</span>
					</h4>
					<FaqItems data={
						data.length>0?faqData1Fetch:faqData1
					} id="frequenty" />
				</div>
				<div className="faq-qck-txt">
					<span id="more-txt" className="target-id"></span>
					{data.length>0?data[0]?.fields["descrizioneHotel"]:`
					Lorem ipsum dolor sit amet consectetur adipiscing elit primis,
					nostra nulla tellus sagittis turpis maecenas duis feugiat nibh,
					fames porttitor faucibus elementum sollicitudin iaculis mauris.
					Sodales integer cubilia est ultricies vivamus potenti enim
					faucibus, porttitor rhoncus ad cursus interdum rutrum aenean
					velit, quis aliquam convallis posuere primis Lorem ipsum dolor
					sit amet consectetur adipiscing elit primis, nostra nulla tellus
					sagittis turpis maecenas duis feugiat nibh, fames porttitor
					faucibus elementum sollicitudin iaculis mauris. Sodales integer
					cubilia est ultricies vivamus potenti enim faucibus, porttitor
					rhoncus ad cursus interdum rutrum aenean velit, quis aliquam
					convallis posuere primis fames porttitor faucibus elementum
					sollicitudin iaculis mauris. Sodales integer cubilia est
					ultricies vivamus potenti enim faucibus, porttitor rhoncus ad
					cursus interdum rutrum aenean velit, quis aliquam convallis
					posuere primis is feugiat nibh, fames porttitor faucibus
					elementum sollicitudin iaculis mauris. Sodales integer cubilia
					est ultricies vivamus potenti enim faucibus, porttitor rhoncus ad
					cursus interdum rutrum aenean velit, quis aliquam convallis
					posuere primis Lorem ipsum dolor sit amet consectetur adipiscing
					elit primis, nostra nulla tellus sagittis turpis maecenas duis
					feugiat nibh, fames porttitor faucibus elementum sollicitudin
					iaculis mauris. Sodales integer cubilia est ultricies vivamus
					potenti enim faucibus, porttitor rhoncus ad cursus interdum
					rutrum aenean velit, quis aliquam convallis posuere primis fames
					porttitor faucibus elementum sollicitudin iaculis mauris. Sodales
					integer cubilia est ultricies vivamus potenti enim faucibus,
					porttitor rhoncus ad cursus interdum rutrum aenean velit, quis
					aliquam convallis posuere primis`}
				</div>
				<h4 className="frequent-title p-0 border-0 mb-3">
					<span className="text-base">
						Dettagli Struttura {data.length>0?data[0]?.fields["nomeHotel"]:""}
					</span>
				</h4>
				<div className="color-base">
					<FaqItems data={
						data.length>0?faqData2Fetch:faqData2
					} id="icon-style" />
				</div>
			</div>
		</section>
	);
};

const FaqTitle = () => (
	<div className="section-title fadeInUp">
		<h3 className="title">La garanzia di Infoischia.com</h3>
		<p className="text">Leggi Le Nostre Recensioni</p>
		<a
			href="https://it.trustpilot.com/review/info-ischia.it?utm_medium=trustbox&utm_source=MicroReviewCount"
			className="d-inline-block"
			target="_blank"
			rel="noreferrer"
		>
			<img src={trustpilot} alt="" />
		</a>
	</div>
);

export default Faqs;
